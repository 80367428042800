<template>
  <div class="layout layout__default">
    <main class="main">
      <layout-sidebar />

      <div class="main__content">
        <layout-header />

        <div class="page-wrapper">
          <router-view />
          <esmp-loader
            v-show="!loading.app && loading.page"
            size="large"
            fix
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const LayoutSidebar = () => import('@/layouts/sidebar/');
const LayoutHeader = () => import('@/layouts/header/');

export default {
  name: 'LayoutDefaultWithoutSearch',

  components: {
    LayoutSidebar,
    LayoutHeader,
  },

  computed: {
    ...mapState('system', ['loading']),
  },

  watch: {
    loading: {
      handler(val) {
        if (val.app) this.$EsmpLoader.show();
        else this.$EsmpLoader.hide();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.main {
  $root: &;
  width: 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  background-color: $color-grayscale-05;

  &__content {
    width: calc(100vw - #{$sidebar-width});
    padding: 0 40px 30px;
    transition: width $base-animation-time;
    flex: 1;
    display: flex;
    flex-direction: column;

    @include for-size(phone-landscape-down) {
      padding: 0 24px 24px;
    }
    @include for-size(phone-portrait-down) {
      padding: 0 20px 20px;
    }
  }

  &--collapse {
    #{$root}__content {
      width: calc(100vw - #{$sidebar-collapse-width});
    }
  }
}
.page-wrapper {
  position: relative;
  width: 100%;
  flex: 1;
}
</style>
